<template>
    <app-wrapper>
        <template v-slot:child>
            <!-- Top content-->
            <InvoiceHeader :contentType="'invoiceSummary'" />

            <InvoiceRecordPaymentModal :closeModal="() => toggleRecordPaymentModal({ status: false, invoice: null })" />

            <VoidInvoice
                :is-open="isVoidModal"
                :title="'Invoice - ' + invoiceReference"
                :close-modal="() => toggleVoidModal(false)"
                :delete-invoice="onDeleteInvoice"
                :disable-delete-button="disableDeleteButton"
            />

            <template v-if="isLoading"><Spinner /></template>

            <!-- desktop / mobile content display -->

            <!-- search, alert, and profile -->
            <!-- <div class="mobile-line">
                <div class="flex justify-between w-90 center pv3">
    
                    <div class="searchContainer">
                        <img src="imgs/search.svg" alt="">
                        <input class="searchBox pl2" type="text" name="search" placeholder="Search...">
                    </div>
    
                    <div class="flex items-center">
                        <div class="notification pr4">
                            <a href="">
                                <img src="imgs/notification.svg" alt="">
                            </a>
                        </div>
                        <div class="profile-dropdown">
                            <a href="">
                                <img src="imgs/profile.svg" alt="">
                            </a>
                        </div>
                    </div> 
                </div>
            </div> -->
            <!-- search, alert, and profile -->

            <!--Invoice Detail -->
            <div v-else class="w-90 center">
                <div class="flex mt4">
                    <div class="flex w-10 justify-between">
                        <div :class="$route.path === '/invoice/summary' ? 'active-menu pa3' : 'pa3'">
                            <router-link
                                :to="{ name: 'InvoiceSummary' }"
                                :class="$route.path === '/invoice/summary' ? active : ''"
                                >Invoice</router-link
                            >
                        </div>
                        <div :class="$route.path === '/quotations' ? 'active-menu pa3' : 'pa3'">
                            <router-link
                                :to="{ name: 'Quotations' }"
                                :class="$route.path === '/quotations' ? active : ''"
                                >Quotations</router-link
                            >
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <!--Invoice Detail -->

            <!-- draft, active payment, awaiting approval, overdue-->
            <div class="w-90 center flex flex-wrap pt4 mb3 adj-text font-w1">
                <!--draft -->
                <div class="w-25-l w-50 pa3 box-border">
                    <div class="pb3">Draft ({{ formatQuantity(state.draftCount) }})</div>
                    <div class="f3 b">{{ formatAmount(state.draftAmount, $store.state.Settings?.currency) }}</div>
                </div>
                <!-- Active payment -->
                <div class="w-25-l w-50 pa3 box-border">
                    <div class="pb3">Paid ({{ formatQuantity(state.activePaymentCount) }})</div>
                    <div class="f3 b">{{ formatAmount(state.activePaymentAmount, $store.state.Settings?.currency) }}</div>
                </div>
                <!-- Awaiting Payment -->
                <div class="w-25-l w-50 pa3 box-border">
                    <div class="pb3">Awaiting Payment ({{ formatQuantity(state.awaitingApprovalCount) }})</div>
                    <div class="f3 b">{{ formatAmount(state.awaitingApprovalAmount, $store.state.Settings?.currency) }}</div>
                </div>
                <!-- overdue -->
                <div class="w-25-l w-50 pa3 box-border">
                    <div class="pb3">Overdue ({{ formatQuantity(state.overDueCount) }})</div>
                    <div class="f3 b">{{ formatAmount(state.overDueAmount, $store.state.Settings?.currency) }}</div>
                </div>
            </div>
            <!-- draft, active payment, awaiting approval, overdue-->

            <!-- Sales, purchase expenses others -->
            <div class="w-90 center pt4">
                <div
                    class="pv4 flex flex-wrap justify-between items-center"
                    style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee"
                >
                    <div class="flex scrollmenu w-40-l w-100 pb0-l pb3">
                        <router-link
                            to="#"
                            @click="changeActiveTable('allInvoices')"
                            :class="state.tableType === 'allInvoices' ? 'active-menu mr3' : 'mr3'"
                            >All</router-link
                        >
                        <router-link
                            to="#"
                            @click="changeActiveTable('drafts')"
                            :class="state.tableType === 'drafts' ? 'active-menu mr3' : 'mr3'"
                            >Draft</router-link
                        >
                        <router-link
                            to="#"
                            @click="changeActiveTable('activePayments')"
                            :class="state.tableType === 'activePayments' ? 'active-menu mr3' : 'mr3'"
                            >Paid</router-link
                        >
                        <router-link
                            to="#"
                            @click="changeActiveTable('awaitingApprovals')"
                            :class="state.tableType === 'awaitingApprovals' ? 'active-menu mr3' : 'mr3'"
                            >Awaiting Payment</router-link
                        >
                        <router-link
                            to="#"
                            @click="changeActiveTable('overdue')"
                            :class="state.tableType === 'overdue' ? 'active-menu mr3' : 'mr3'"
                            >Overdue</router-link
                        >
                    </div>
                    <div class="flex flex-wrap justify-between items-center w-50-l w-100 mt0-l mt2">
                        <select
                            v-model="state.dateFilter"
                            @change="changeDateFilter"
                            class="card-box mb3 mb0-l"
                            id=""
                            style="padding: 13px 0"
                        >
                            <option value="">All</option>
                            <option value="last7Days">Last 7days</option>
                            <option value="currentMonth">This Month</option>
                            <option value="lastMonth">Last Month</option>
                        </select>
                        <input
                            type="date"
                            name=""
                            class="input-style card-box mb3 mb0-l"
                            placeholder="Search..."
                            v-model="state.dateRange.startDate"
                            style="padding: 13px 0"
                        />
                        <input
                            type="date"
                            name=""
                            class="input-style card-box mb3 mb0-l"
                            placeholder="Search..."
                            v-model="state.dateRange.endDate"
                            @change="changeDateSearch"
                            style="padding: 13px 0"
                        />
                        <!-- <div class="searchmenu card-box">
                            <img src="imgs/search.svg" class="pa1" alt="" />
                            <input class="searchBox pl2" type="text" name="" v-model="state.searchBox" placeholder="Search..." />
                        </div> -->
                        <div class="filter card-box" style="padding: 13px 0">
                            <div class="tc flex items-center">
                                <img src="imgs/filter_list.svg" class="pl3" alt="" />
                                <router-link to="#" @click="filterTable()" class="pl4">Filter</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Sales, purchase expenses others -->

            <div class="scrollytable w-90 center">
                <table class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 pr3 box-border-bottom tl">Number</th>
                            <th class="pb3 pr3 box-border-bottom tl">Ref</th>
                            <th class="pb3 pr3 box-border-bottom tl">Bill To</th>
                            <th class="pb3 box-border-bottom tl pr2">Issue date</th>
                            <th class="pb3 pr3 box-border-bottom tl">Due Date</th>
                            <th class="pb3 pr3 box-border-bottom tl">Amount</th>
                            <th class="pb3 pr3 box-border-bottom tl">Amount Due</th>
                            <th class="pb3 box-border-bottom tl">No. of Days Due</th>
                            <th class="pb3 box-border-bottom tl">Status</th>
                            <th class="pb3 box-border-bottom tl">Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="state.activeTable.length">
                        <tr v-for="invoice of state.activeTable" v-bind:key="invoice" class="font-w1">
                            <td class="pv3 pr3 box-border-bottom tl b">
                                <router-link :to="{ name: 'Invoice', params: { id: invoice?._id } }">
                                    {{ invoice.number }}
                                </router-link>
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl b">
                                <router-link :to="{ name: 'Invoice', params: { id: invoice?._id } }">
                                    {{ invoice.reference }}
                                </router-link>
                            </td>
                            <td class="pv3 pr4 box-border-bottom tl b">
                                <router-link
                                    v-if="invoice?.customer?.id"
                                    :to="{ name: 'Entity', params: { id: invoice?.customer?.id } }"
                                >
                                    {{ invoice?.customer?.name }}
                                </router-link>
                                <span v-else>{{ invoice?.customer?.name }}</span>
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(invoice.dateIssued) }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(invoice.dateDue) }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(invoice.totalAmount, $store.state.Settings?.currency) }}
                            </td>
                            <td>
                                {{
                                    formatAmount(
                                        invoice.totalAmount - (invoice?.deposit || 0),
                                        invoice.currency || $store.state.Settings?.currency
                                    )
                                }}
                            </td>
                            <td
                                v-if="
                                    invoice.status.includes('paid') ||
                                    invoice.status.includes('void') ||
                                    invoice.status === 'draft'
                                "
                                class="pv3 pr3 box-border-bottom tc"
                            >
                                -
                            </td>
                            <td v-else class="pv3 pr3 box-border-bottom tc">
                                <span :class="{ 'text-danger': getDaysFromToday(new Date(invoice.dateDue)) < 0 }">{{
                                    getDaysFromToday(new Date(invoice.dateDue)) || 0
                                }}</span>
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{
                                    invoice.status.includes('void')
                                        ? 'Voided'
                                        : invoice.status.includes('paid')
                                        ? 'Paid'
                                        : invoice.status.includes('part_payment')
                                        ? 'Part payment'
                                        : invoice.status.includes('writeOff')
                                        ? 'writeOff'
                                        : state.tableType == 'allInvoices'
                                        ? invoice.status
                                        : tableStatus[state.tableType]
                                }}
                            </td>
                            <td class="items-center pv3 pr3 box-border-bottom tc">
                                <div class="dropdown" style="position: relative">
                                    <a
                                        class="dropdown-togglex"
                                        href="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="fa-2x" />
                                    </a>

                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li
                                            v-if="
                                                invoice.status !== 'draft' &&
                                                !invoice.status.includes('void') &&
                                                !invoice.status.includes('writeOff') &&
                                                !invoice.status.includes('paid') &&
                                                (invoice.status === 'awaiting_payment' || invoice.status.includes('part_payment'))
                                            "
                                        >
                                            <a
                                                class="dropdown-itemx"
                                                href="#"
                                                @click="toggleRecordPaymentModal({ status: true, invoice })"
                                            >
                                                Record payment
                                            </a>
                                        </li>
                                        <li>
                                            <router-link
                                                :to="{ name: 'Invoice', params: { id: invoice?._id } }"
                                                class="dropdown-itemx"
                                            >
                                                View
                                            </router-link>
                                        </li>
                                        <li
                                            v-if="
                                                (role === 'admin' || role === 'owner' || rolePermissions.includes(41)) &&
                                                !invoice.status.includes('void') &&
                                                !invoice.status.includes('writeOff') &&
                                                (invoice.status.includes('paid') || invoice.status !== 'draft')
                                            "
                                        >
                                            <!-- @click="onUpdate(invoice, 'void')" -->
                                            <a class="dropdown-itemx" @click.prevent="confirmVoid(invoice)" href="">
                                                Cancel/Void
                                            </a>
                                        </li>
                                        <li v-if="invoice?.status === 'awaiting_payment'">
                                            <a
                                                href=""
                                                class="dropdown-itemx"
                                                v-if="!invoice.status.includes('paid')"
                                                @click.prevent="sendInvoiceAsPdf(invoice?._id)"
                                            >
                                                Send Reminder
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="dropdown-itemx" @click="duplicateInvoice(invoice)"> Duplicate </a>
                                        </li>
                                        <li
                                            v-if="
                                                invoice.status !== 'draft' &&
                                                invoice.status !== 'awaiting_payment' &&
                                                !invoice.status.includes('void') &&
                                                !invoice.status.includes('writeOff') &&
                                                !invoice.status.includes('paid') &&
                                                !invoice.status.includes('part_payment')
                                            "
                                        >
                                            <a class="dropdown-itemx" @click="onUpdate(invoice, 'writeOff')" href="">
                                                Write-off
                                            </a>
                                        </li>
                                        <li v-if="invoice.status === 'draft'">
                                            <a href="" @click.prevent="deleteDraftInvoice(invoice._id)"> Delete </a>
                                        </li>
                                        <li
                                            v-if="
                                                invoice?.status === 'awaiting_payment' || invoice.status.includes('part_payment')
                                            "
                                        >
                                            <a
                                                href=""
                                                class="dropdown-itemx"
                                                @click.prevent="openWhatsapp(invoice, state.shareUrl, invoice._id)"
                                            >
                                                Share via WhatsApp
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9">
                                <TableEmptyState :data-array="state.activeTable" message="No invoice record" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- pagination -->
            <div v-if="state.activeTable.length" class="w-90 center pb5 font-w1">
                <div class="flex justify-between items-center pa3" style="background: #f6f8fe">
                    <div class="flex items-center">
                        <div class="pr3-l pr2">Showing</div>
                        <!-- w-20 -->
                        <input
                            type="number"
                            name=""
                            class="input-style w-20"
                            :placeholder="state.pageSize ?? state.activeTable.length"
                            style="border: 1px solid rgb(151, 145, 145); background: #ffffff"
                            v-model="state.pageSize"
                            @change="changePageSize"
                            :min="1"
                        />
                        <div class="pl3 db-l dn">items per page</div>
                    </div>
                    <div class="flex">
                        <router-link to="#" class="active-page-number">{{ state.pageNumber }}</router-link>
                        <!-- <router-link to="#" @click="changePageNumber(state.pageNumber + 1)" class="active-page-number pl2 pl3-l">{{state.pageNumber + 1}}</router-link> -->
                        <router-link v-if="state.totalPages > 1" to="#" @click.prevent="changePageNumber" class="pl2 pl3-l"
                            >Next</router-link
                        >
                        <router-link v-if="state.totalPages > 1" to="#" @click="goLastPage" class="pl2 pl3-l">End</router-link>
                    </div>
                    <!-- Paginate -->
                </div>
            </div>
            <!-- pagination -->
            <!-- Sales, purchase expenses others -->
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, watch, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
    formatAmount,
    formatQuantity,
    getDaysFromToday,
    formatDate,
    formatDateFilter,
    getThisMonthDateRange,
    getLastMonthDateRange,
    getLastSevenDaysDateRange,
    getTotalPages,
    paginate2,
} from '@/utils/lib'
import Swal from 'sweetalert2'
import AppWrapper from '@/layout/AppWrapper'
import InvoiceHeader from '@/views/Invoice/widgets/InvoiceHeader'
import Pagination from '@/components/Pagination'
import InvoiceRecordPaymentModal from '@/components/InvoiceRecordPaymentModal'
import VoidInvoice from '@/components/voidInvoice'
import Spinner from '@/components/Spinner'
import TableEmptyState from '@/components/TableEmptyState'
import ShortUniqueId from 'short-unique-id'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
// import Filter from '@/components/Filter'

export default {
    name: 'InvoiceSummary',
    components: {
        AppWrapper,
        InvoiceHeader,
        Pagination,
        Spinner,
        TableEmptyState,
        InvoiceRecordPaymentModal,
        InvoiceStatus,
    },
    setup() {
        const router = useRouter()
        const store = useStore()
        const route = useRoute()
        const isLoading = computed(() => store.state.Invoice.isLoading)
        const invoiceSummary = computed(() => store.state.Invoice.invoiceSummary)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const disableDeleteButton = ref(false)
        const isVoidModal = ref(false)
        const invoiceReference = ref('')
        const invoiceID = ref('')

        const invoiceNumber = computed(() => store.state.Invoice.invoiceNumber)
        // initialize the invoice summary

        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        const day = today.getDate()

        const intialState = {
            allInvoicesCount: 0,
            allInvoicesAmount: 0,
            draftCount: 0,
            draftAmount: 0,
            activePaymentCount: 0,
            activePaymentAmount: 0,
            awaitingApprovalCount: 0,
            awaitingApprovalAmount: 0,
            overDueCount: 0,
            overDueAmount: 0,

            allInvoices: [],
            drafts: [],
            activePayments: [],
            awaitingApprovals: [],
            overdue: [],

            activeTable: [],
            tableType: 'allInvoices',
            summaryType: 'all',
            dateFilter: '',
            dateSearch: '',
            searchBox: '',
            dateRange: {
                startDate: formatDateFilter(new Date(2020, 0, 1)),
                endDate: formatDateFilter(new Date()),
            },

            pageNumber: 1,
            pageSize: 10,
            totalPages: null,

            lastInvoiceNumber: 0,
            shareUrl: '',
        }

        const state = reactive(intialState)

        console.log("hereeeee", state.activeTable)

        const toggleVoidModal = (modalState = false, e = null) => {
            // console.log(e)
            isVoidModal.value = modalState
            invoiceReference.value = e?.reference
            disableDeleteButton.value = false
        }

        const onDeleteInvoice = () => {
            // start
        }
        const sendInvoiceAsPdf = async (invoiceID) => {
            store.dispatch('Invoice/sendInvoiceAsPdf', {
                id: invoiceID,
                template: 'default',
            })
        }
        const changePageSize = () => {
            if (parseFloat(state.pageSize) === 0) state.pageSize = 1
            let paginationTable = state[state.tableType]
            state.totalPages = getTotalPages(state.activeTable, state.pageSize)
            if (state.pageNumber > state.totalPages) state.pageNumber = state.totalPages
            paginationTable = paginate2(paginationTable, state.pageNumber, state.pageSize)

            state.activeTable = paginationTable
        }

        const changePageNumber = () => {
            if (parseFloat(state.pageNumber) === 0) state.pageNumber = 1
            let paginationTable = state[state.tableType]
            state.pageNumber = state.pageNumber + 1
            // console.log(state.pageNumber, state.pageSize, state.totalPages)
            state.totalPages = getTotalPages(paginationTable, state.pageSize)
            if (state.pageNumber >= parseFloat(state.totalPages)) state.pageNumber = state.totalPages
            paginationTable = paginate2(paginationTable, state.pageNumber, state.pageSize)

            state.activeTable = paginationTable
        }

        // watch for invoice summary
        watch(
            () => invoiceSummary.value,
            (newValue, prevValue) => {
                if (newValue !== prevValue) {
                    const allInvoicesSummary = newValue.find((item) => item.status === 'all')
                    const dratSummary = newValue.find((item) => item.status === 'draft')
                    const activePaymentSummary = newValue.find((item) => item.status === 'paid')
                    const awaitingApprovalSummary = newValue.find((item) => item.status === 'awaiting_payment')
                    const overDueSummary = newValue.find((item) => item.status === 'overdue')

                    state.allInvoicesCount = allInvoicesSummary.count
                    state.allInvoicesAmount = allInvoicesSummary.totalAmount
                    state.allInvoices = allInvoicesSummary.invoices

                    state.draftCount = dratSummary.count
                    state.draftAmount = dratSummary.totalAmount
                    state.drafts = dratSummary.invoices

                    state.activePaymentCount = activePaymentSummary.count
                    state.activePaymentAmount = activePaymentSummary.totalAmount
                    state.activePayments = activePaymentSummary.invoices

                    state.awaitingApprovalCount = awaitingApprovalSummary.count
                    state.awaitingApprovalAmount = awaitingApprovalSummary.totalAmount
                    state.awaitingApprovals = awaitingApprovalSummary.invoices

                    state.overDueCount = overDueSummary.count
                    state.overDueAmount = overDueSummary.totalAmount
                    state.overdue = overDueSummary.invoices

                    state.activeTable = state[state.tableType]

                    const lastnumber =
                        overDueSummary.count + activePaymentSummary.count + awaitingApprovalSummary.count + dratSummary.count

                    state.lastInvoiceNumber = lastnumber
                }
            }
        )

        // watch for the active table
        watch(
            () => state.activeTable,
            (newValue, prevValue) => {
                if (newValue !== prevValue) {
                    // console.log('active table changed', newValue)
                }
            }
        )

        onMounted(() => {
            // const query = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
            const query = {
                limit: '',
                page: '',
                date: '',
                type: '',
            }
            store.dispatch('Invoice/getInvoiceSummary', query)
            // store.dispatch('Inventory/getAllServices', query)
            store.dispatch('Settings/getBankAccounts')
        })

        const changeActiveTable = (tableName) => {
            if (tableName === 'allInvoices') {
                state.activeTable = state.allInvoices
                state.tableType = 'allInvoices'
            } else if (tableName === 'drafts') {
                state.activeTable = state.drafts
                state.tableType = 'drafts'
            } else if (tableName === 'activePayments') {
                //console.log('active payments')
                state.activeTable = state.activePayments
                state.tableType = 'activePayments'
            } else if (tableName === 'awaitingApprovals') {
                state.activeTable = state.awaitingApprovals
                state.tableType = 'awaitingApprovals'
            } else if (tableName === 'overdue') {
                state.activeTable = state.overdue
                state.tableType = 'overdue'
            }
        }

        const changeSummaryTable = (summaryType) => {
            // display all invoices
            state.summaryType = summaryType
            const query = {
                limit: '',
                page: '',
                date: '',
                type: summaryType,
            }

            store.dispatch('Invoice/getInvoiceSummary', query)
        }

        const tableStatus = {
            activePayments: 'Paid',
            awaitingApprovals: 'Awaiting Payment',
            overdues: 'Overdue',
            drafts: 'Draft',
            allInvoices: 'All Invoices',
        }
        const filterTable = () => {
            const query = {
                limit: '',
                page: '',
                date: state.dateRange,
                type: state.summaryType,
                search: state.searchBox,
            }
            store.dispatch('Invoice/getInvoiceSummary', query)
        }

        const changeDateFilter = () => {
            if (state.dateFilter === 'currentMonth') {
                // state.dateRange = getThisMonthDateRange()
                const { startDate, endDate } = getThisMonthDateRange()
                state.dateRange.startDate = formatDateFilter(startDate)
                state.dateRange.endDate = formatDateFilter(endDate)
            } else if (state.dateFilter === 'lastMonth') {
                // state.dateRange = getLastMonthDateRange()
                const { startDate, endDate } = getLastMonthDateRange()
                state.dateRange.startDate = formatDateFilter(startDate)
                state.dateRange.endDate = formatDateFilter(endDate)
            } else if (state.dateFilter === 'last7Days') {
                // state.dateRange = getLastSevenDaysDateRange()
                const { startDate, endDate } = getLastSevenDaysDateRange()
                state.dateRange.startDate = formatDateFilter(startDate)
                state.dateRange.endDate = formatDateFilter(endDate)
            } else if (state.dateFilter === '') {
                state.dateRange.startDate = formatDateFilter(new Date(2020, 0, 1))
                state.dateRange.endDate = formatDateFilter(new Date())
            }

            const query = {
                limit: '',
                page: '',
                date: state.dateRange,
                type: state.summaryType,
                search: state.searchBox,
            }
            store.dispatch('Invoice/getInvoiceSummary', query)
        }

        const changeDateSearch = () => {
            const query = {
                limit: '',
                page: '',
                date: state.dateSearch,
                type: state.summaryType,
                search: state.searchBox,
            }

            store.dispatch('Invoice/getInvoiceSummary', query)
        }

        const duplicateInvoice = (invoice) => {
            const { _id, reference, number, status, paymentInfo, last_payment_date, due_date, deposit, ...data } = invoice
            const lastInvoice = state.lastInvoiceNumber + 1
            const shortName = invoice.business.name.split(' ')
            const firstLetter = shortName[1]
                ? shortName[0].charAt(0) + shortName[1].charAt(0)
                : shortName[0].charAt(0) + shortName[0].charAt(1)
            // pad with four zeros
            const paddedNumber = ('0000' + lastInvoice).slice(-4)
            const computedInvoiceNumber = firstLetter.toUpperCase() + 'D' + paddedNumber
            // console.log(state.invoice.number, 'invoice number');

            let invoicePayload = {
                ...data,
                status: 'draft',
                number: computedInvoiceNumber,
                reference: new ShortUniqueId({ length: 6 }).randomUUID(),
                duplicate: true,
            }

            store.dispatch('Invoice/saveInvoice', invoicePayload)
        }

        const deleteDraftInvoice = (id) => {
            Swal.fire({
                title: `Are you sure you want to delete this draft invoice?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Continue',
                denyButtonText: `No, Cancel`,
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('Invoice/deleteDraftInvoice', id).then((_) => {
                        const query = { limit: '', page: '', date: '', type: '' }
                        store.dispatch('Invoice/getInvoiceSummary', query)
                    })
                }
            })
        }

        const toggleRecordPaymentModal = (payload) => {
            store.dispatch('Invoice/showRecordPaymentModal', payload)
        }

        const onUpdate = (invoice, status) => {
            if (status === 'paid') invoice.status = ['paid']
            else if (status === 'void') invoice.status = ['void']
            else if (status === 'writeOff') invoice.status = ['writeOff']

            store.dispatch('Invoice/updateInvoice', invoice)
        }

        const confirmVoid = (invoice) => {
            // console.log(invoice)
            Swal.fire({
                title: `Are you sure you want to cancel/void invoice - ${invoice.reference}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Void!',
                denyButtonText: `No, cancel!`,
            }).then((result) => {
                if (result.isConfirmed) {
                    invoice.status = ['void']
                    store.dispatch('Invoice/updateInvoice', invoice)
                }
            })
        }

        const openWhatsapp = (inv, shareUrl, id) => {
            const phoneNumber = inv.customer.phone.replace(/\D/g, '').slice(-10);
            const message = `
Hi ${inv.customer.name.split(' ')[0]},
Your invoice ${inv.reference} for the sum of ${inv.totalAmount}${inv.currency} on ${inv.dateIssued} from ${
                inv.business.name
            } is ready for you. View it here: ${shareUrl + `/${id}/view`}
If you have any questions, contact us at ${inv.business.email} or ${inv.business.phone}.
Thanks for choosing ${inv.business.name}.
Best regards, ${inv.business.name}`
            const encodedMessage = encodeURIComponent(message).replace(/%0A/g, '%0A%0A')
            const whatsappURL = `https://wa.me/+234${phoneNumber}?text=${encodedMessage}`
            window.open(whatsappURL, '_blank')
        }

        onMounted(() => {
            state.shareUrl = `${process.env.VUE_APP_BASE_URL}/invoice`
            //store.dispatch('Invoice/getInvoice', route.params.id)

            //console.log(state.shareUrl)
        })
        return {
            isLoading,
            formatAmount,
            changeActiveTable,
            formatQuantity,
            state,
            getDaysFromToday,
            tableStatus,
            formatDate,
            changeSummaryTable,
            changeDateSearch,
            changeDateFilter,
            filterTable,
            changePageSize,
            changePageNumber,
            duplicateInvoice,
            onUpdate,
            toggleRecordPaymentModal,
            rolePermissions,
            role,
            deleteDraftInvoice,
            toggleVoidModal,
            invoiceReference,
            disableDeleteButton,
            isVoidModal,
            onDeleteInvoice,
            confirmVoid,
            sendInvoiceAsPdf,
            openWhatsapp,
        }
    },
}
</script>

<style>
:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
}
.directional-up {
    width: 15px;
    height: 15px;
}
.sort1 {
    padding: 6px;
    cursor: pointer;
}
.sort1:hover {
    background-color: rgb(237, 240, 253);
    color: #132c8c;
    border-radius: 5px;
}
.invoice-actions a {
    width: 22px;
}

.icon-80 {
    width: 80%;
}

@media screen and (max-width: 767px) {
    .scrollytable {
        overflow-x: auto;
        white-space: nowrap !important;
    }
}
</style>
